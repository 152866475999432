<template>
  <div>
    <div class="d-flex justify-content-between">
      <!-- LEFT SIDE -->
      <div class="col-12 col-lg-6 px-4">
        <h2 class="highlighted-color1 mb-4">
          <span>{{ $t('my_profile') }}</span>
        </h2>
        <form @submit.prevent="submit" class="default-form" v-if="user">
          <div class="form-group mb-3">
            <label class="form-label fw-bold">{{ $t('full_name') }}</label>
            <input type="text" class="form-control" :placeholder="$t('full_name')" v-model.trim="$v.form.name.$model" />
          </div>
          <div class="form-group mb-3">
            <label class="form-label fw-bold">{{ $t('email') }}</label>
            <input type="email" class="form-control" placeholder="email@email.com.br" v-model.trim="$v.form.email.$model" />
          </div>
          <div class="form-group mb-3">
            <label class="form-label fw-bold">{{ $t('phone') }}</label>
            <PhoneInput
              v-model.trim="$v.form.phone.$model"
              :country="user.company.country"
            />
          </div>
          <div class="text-center mt-3">
            <Button
              type="submit"
              class="btn btn-primary"
              :disabled="$v.$invalid"
              :loading="loader === 'settings'"
            >
              {{ $t('save') }}
            </Button>
          </div>
        </form>
      </div> <!-- END LEFT SIDE -->
      <!-- RIGHT SIDE -->
      <div class="col-12 col-lg-6 ps-4">
        <div class="mb-4">
          <h2 class="highlighted-color1 mb-4">
            <span>{{ $t('reset_password') }}</span>
          </h2>
           <div class="d-flex justify-content-between align-items-center">
             <div class="col-12 col-lg-9">
               <p class="mb-0">{{ $t('email_reset_password') }}</p>
             </div>
             <div>
              <Button
                type="button"
                class="btn btn-primary btn-sm text-nowrap"
                @click="sendResetPasswordLink"
                :loading="loader === 'password'"
              >
                {{ $t('change_password') }}
              </Button>
             </div>
           </div>
        </div>
        <div v-if="user">
          <!-- 2FA -->
          <div class="mb-4">
            <h2 class="highlighted-color1 mb-4">
              <span>{{ $t('two_factor_authentication') }}</span>
            </h2>
            <div class="d-flex justify-content-between align-items-center">
              <div class="col-12 col-lg-9">
                <p class="mb-0">{{ $t('info_two_factor_authentication') }}</p>
              </div>
              <div>
                <Button
                  type="button"
                  class="btn btn-sm"
                  :class="{
                    'btn-primary': !user.use_2fa,
                    'btn-danger': user.use_2fa
                  }"
                  @click="toggle2FA"
                  :loading="loader === '2fa'"
                >
                  {{ user.use_2fa ? $t('disable') : $t('enable') }}
                </Button>
              </div>
            </div>
          </div> <!-- END 2FA -->
          <!-- EMAILS ENABLED -->
          <div class="mb-3">
            <h2 class="highlighted-color1 mb-4">
              <span>{{ $t('receiving_emails') }}</span>
            </h2>
            <div class="d-flex justify-content-between align-items-center">
              <div class="col-12 col-lg-9">
                <p class="mb-0">
                  {{ $t('info_receiving_emails') }}<br/>
                  <small><strong>{{ $t('important') }}:</strong> {{ $t('info_receiving_emails2') }}</small>
                </p>
              </div>
              <div>
                <Button
                  type="button"
                  class="btn btn-sm"
                  :class="{
                    'btn-primary': !user.is_emails_enabled,
                    'btn-danger': user.is_emails_enabled
                  }"
                  @click="toggleEmailsEnabled"
                  :loading="loader === 'emails-enabled'"
                >
                  {{ user.is_emails_enabled ? $t('disable') : $t('enable') }}
                </Button>
              </div>
            </div>
          </div> <!-- END EMAILS ENABLED -->
          <!-- WHATSAPP ENABLED -->
          <div class="mb-3">
            <h2 class="highlighted-color1 mb-4">
              <span>{{ $t('whatsapp_receiving') }}</span>
            </h2>
            <div class="d-flex justify-content-between align-items-center">
              <div class="col-12 col-lg-9">
                <p class="mb-0">
                  {{ $t('info_whatsapp_receiving') }}<br/>
                  <small><strong>{{ $t('important') }}:</strong> {{ $t('info_whatsapp_receiving2') }}</small>
                </p>
              </div>
              <div>
                <Button
                  type="button"
                  class="btn btn-sm"
                  :class="{
                    'btn-primary': !user.is_whatsapp_enabled,
                    'btn-danger': user.is_whatsapp_enabled
                  }"
                  @click="toggleWhatsappEnabled"
                  :loading="loader === 'whatsapp-enabled'"
                >
                  {{ user.is_whatsapp_enabled ? $t('disable') : $t('enable') }}
                </Button>
              </div>
            </div>
          </div> <!-- END WHATSAPP ENABLED -->
        </div>
      </div> <!-- END RIGHT SIDE -->
    </div>
  </div>
</template>

<script>

import AuthService from '@/modules/auth/services/auth-service'
import NotificationService from '@/modules/notifications/services/notifications-service'
import UsersService from '@/modules/users/services/users-service'
import { required, email } from 'vuelidate/lib/validators'
import { mapState } from 'vuex'

import Button from '@/components/common/Button/Button'
import PhoneInput from '@/components/common/Input/PhoneInput'

export default {
  components: {
    Button,
    PhoneInput
  },
  data () {
    return {
      loader: '',
      form: {
        name: '',
        email: '',
        phone: ''
      },
      phone_mask: ''
    }
  },
  metaInfo () {
    return {
      title: this.$t('my_profile')
    }
  },
  validations () {
    return {
      form: {
        name: { required },
        email: { required, email },
        phone: { required }
      }
    }
  },
  created () {
    this.setUser()
  },
  watch: {
    user () {
      this.setUser()
    }
  },
  computed: {
    ...mapState('users', {
      user: state => state.user
    })
  },
  methods: {
    /**
     * Send password reset link
     */
    async sendResetPasswordLink () {
      this.loader = 'password'
      try {
        await AuthService.sendResetPasswordEmail(this.user.email)
        const notification = {
          notification: {
            type: 'success',
            content: this.$t('success_message_reset_password')
          }
        }
        NotificationService.createGlobalSuccessNotification(notification)
      } catch (error) {
        error.handleGlobally && error.handleGlobally()
      }
      this.loader = ''
    },
    /**
     * Set user data to form
     */
    setUser () {
      if (this.user) {
        this.form.name = this.user.name
        this.form.email = this.user.email
        this.form.phone = this.user.phone
      }
    },
    /**
     * Update user
     */
    async submit () {
      this.loader = 'settings'
      try {
        const form = Object.assign({}, this.form)
        form.phone = form.phone.replace('+', '').replace(/\s/g, '')
        await UsersService.updateUser(this.user.id, form)
        await UsersService.me()
        const notification = {
          notification: {
            type: 'success',
            content: this.$t('success_message_update_user')
          }
        }
        NotificationService.createGlobalSuccessNotification(notification)
      } catch (error) {
        error.handleGlobally && error.handleGlobally()
      }
      this.loader = ''
    },
    /**
     * Toggle 2FA use
     */
    async toggle2FA () {
      this.loader = '2fa'
      try {
        await UsersService.toggle2FA(this.user.id)
        await UsersService.me()
        const notification = {
          notification: {
            type: 'success',
            content: this.$t('success_message_2fa')
          }
        }
        NotificationService.createGlobalSuccessNotification(notification)
      } catch (error) {
        error.handleGlobally && error.handleGlobally()
      }
      this.loader = ''
    },
    /**
     * Toggle emails enabled
     */
    async toggleEmailsEnabled () {
      this.loader = 'emails-enabled'
      try {
        await UsersService.toggleEmailsEnabled(this.user.id)
        await UsersService.me()
        const notification = {
          notification: {
            type: 'success',
            content: this.$t('success_message_receiving_emails')
          }
        }
        NotificationService.createGlobalSuccessNotification(notification)
      } catch (error) {
        error.handleGlobally && error.handleGlobally()
      }
      this.loader = ''
    },
    /**
     * Toggle whatsapp enabled
     */
    async toggleWhatsappEnabled () {
      this.loader = 'whatsapp-enabled'
      try {
        await UsersService.toggleWhatsappEnabled(this.user.id)
        await UsersService.me()
        const notification = {
          notification: {
            type: 'success',
            content: this.$t('success_message_whatsapp_receiving')
          }
        }
        NotificationService.createGlobalSuccessNotification(notification)
      } catch (error) {
        error.handleGlobally && error.handleGlobally()
      }
      this.loader = ''
    }
  }
}
</script>
